@import "colors.less";

// Background Colors
.bg-primary, .bg-teal {
  background-color: @color-primary;
  color: #fff;
}
.bg-interactive, .bg-blue {
  background-color: @color-interactive;
  color: #fff;
}
.bg-secondary, .bg-peach {
  background-color: @color-secondary;
  color: @color-text-primary;
}
.bg-cream {
  background-color: @color-cream;
  color: @color-text-primary;
}
.bg-text-primary {
  background-color: @color-text-primary;
  color: @color-white;
}
.bg-dark-teal {
  background-color: @color-dark-teal;
  color: @color-white;
}
.bg-premium {
  background-color: @color-premium;
  color: @color-white;
}
.bg-black {
  background-color: @color-black;
  color: #fff;
}
.bg-dark-gray87 {
  background-color: @color-dark-gray87;
  color: #fff;
}
.bg-dark-gray9F {
  background-color: @color-dark-gray9F;
  color: #fff;
}
.bg-grayC5 {
  background-color: @color-grayC5;
  color: #fff;
}
.bg-light-grayDB {
  background-color: @color-light-grayDB;
  color: @color-text-primary;
}
.bg-light-grayED {
  background-color: @color-light-grayED;
  color: @color-text-primary;
}
.bg-white {
  background-color: @color-white;
  color: @color-text-primary;
}
.bg-ecru {
  background-color: @color-ecru;
  color: @color-text-primary;
}

/******old - delete if not used **************/
.bg-red {
  //background-color: @color-red;
  color: #fff;
}
.bg-yellow {
  //background-color: @color-yellow;
  color: #fff;
}
.bg-brown {
  //background-color: @color-brown;
  color: #fff;
}
.bg-green {
  //background-color: @color-green;
  color: #fff;
}
.bg-purple {
  //background-color: @color-purple;
  color: #fff;
}
.bg-gradient {
  color: #fff;
  background-color: #00b5ba;
}
.bg-gray2 {
  ///background-color: @color-gray2;
  color: #fff;
}
.bg-gray4 {
  //background-color: @color-gray4;
  color: #fff;
}
.bg-gray6 {
  //background-color: @color-gray6;
  color: #fff;
}
.bg-gray8 {
  //background-color: @color-gray8;
  color: #fff;
}
.bg-grayC, .bg-grayc {
  //background-color: @color-grayC;
  color: #fff;
}
.bg-grayD, .bg-grayd {
  //background-color: @color-grayD;
}
.bg-grayF2, .bg-grayf2, .bg-grayF, .bg-grayf {
  //background-color: @color-grayF2;
}
.bg-white {
  background-color: #fff;
}
.bg-worksheet {
  background-color: @color-worksheet;
  color: #fff;
}
.bg-game {
  background-color: @color-game;
  color: #fff;
}
.bg-workbook {
  background-color: @color-workbook;
  color: #fff;
}
.bg-activity {
  background-color: @color-activity;
  color: #fff;
}
.bg-lesson-plan {
  background-color: @color-lesson-plan;
  color: #fff;
}
.bg-science-project {
  background-color: @color-science-project;
  color: #fff;
}
.bg-song {
  background-color: @color-song;
  color: #fff;
}
.bg-story {
  background-color: @color-story;
  color: #fff;
}
.bg-exercise {
  background-color: @color-exercise;
  color: #fff;
}
.bg-guided-lesson {
  background-color: @color-guided-lesson;
  color: #fff;
}

// Text Colors
.color-teal {
  color: @color-teal;
}
.color-teal-light1 {
  color: @color-teal-light1;
}
.color-teal-light2 {
  color: @color-teal-light2;
}
.color-teal-light3 {
  color: @color-teal-light3;
}
.color-teal-light4 {
  color: @color-teal-light4;
}
.color-teal-light5 {
  color: @color-teal-light5;
}
.color-teachers {
  color: @color-teachers;
}
.color-parents {
  color: @color-parents;
}

.color-primary, .color-orange {
  color: @color-primary;
}
.color-secondary, .color-blue {
  //color: @color-blue;
}
.color-red {
 // color: @color-red;
}
.color-yellow {
  //color: @color-yellow;
}
.color-brown {
  //color: @color-brown;
}
.color-green {
  //color: @color-green;
}
.color-purple {
  //color: @color-purple;
}
.color-black {
  //color: @color-black;
}
.color-gray2 {
  //color: @color-gray2;
}
.color-gray4 {
  //color: @color-gray4;
}
.color-gray6 {
  //color: @color-gray6;
}
.color-gray8 {
  //color: @color-gray8;
}
.color-grayC, .color-grayc {
  //color: @color-grayC;
}
.color-grayD, .color-grayd {
  //color: @color-grayD;
}
.color-grayF2, .color-grayf2, .color-grayF, .color-grayf {
  //color: @color-grayF2;
}
.color-white {
  color: #fff;
}
.color-worksheet {
  color: @color-worksheet;
}
.color-game {
  color: @color-game;
}
.color-workbook {
  color: @color-workbook;
}
.color-activity {
  color: @color-activity;
}
.color-lesson-plan {
  color: @color-lesson-plan;
}
.color-science-project {
  color: @color-science-project;
}
.color-song {
  color: @color-song;
}
.color-story {
  color: @color-story;
}
.color-exercise {
  color: @color-exercise;
}
.color-guided-lesson {
  color: @color-guided-lesson;
}
