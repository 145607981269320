@import (reference) "../styles/colors.less";

.tt-hint {
  visibility: hidden;
}

.search-form.form, .nav-search-form {
  span.twitter-typeahead {
    background: white;
    position: relative;
    display: inherit !important;
    width: 100%;
    border-radius: 30px;
  }

  .tt-menu { /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
    width: 100%;
    margin-top: 5px;
    background-color: @color-white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    box-shadow: 0 2px 7px rgba(0,0,0,.2);
    padding: 0 !important;
    position: relative;
    text-align: left;
    z-index: 10000 !important;
    user-select: none;
    overflow: hidden;

    &.tt-open {
      .searchAllBox {
        word-break: break-all;
        padding: 7px 31px 9px 20px;
        background: @color-white;
        border-top: 1px solid @color-grayC5;
        border-radius: 0 0 20px 20px;
      }

      .browseAllLink {
        margin-top: 8px;
        display: flex;
        gap: 6px;
        border-top: 1px solid rgba(0, 0, 0, .22);
        line-height: 24px;
        padding: 7px 31px 9px 18px;
        color: inherit;
        text-decoration: none;

        i {
          color: @color-blue-sapphire
        }
      }
    }

    .tt-suggestion, .empty-results {
      padding: 6px 20px;
      font-size: 16px;
      line-height: 24px;
    }

    .tt-suggestion {
      margin: 0;
      font-family: TTNorms, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      &.popularSearches {
        font-family: TTNorms, Arial, sans-serif;
        font-weight: 400;
      }
    }

    @media (max-width: @screen-phone-lg) {
      .tt-suggestion, .tt-suggestion.popularSearches {
        font-size: 14px;
      }
    }

    .tt-suggestion:hover {
      cursor: pointer !important;
      background: #f7f7f7 !important;

    }

    .tt-suggestion.tt-cursor { /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
      color: #333;
      background-color: #f7f7f7;
    }

    .tt-suggestion p {
      margin: 0;
    }

    .tt-dataset-popular_search {
      .popularSearchesTitle {
        padding-left: 20px;
        padding-top: 10px;
        color: var(--Dark-gray, @color-gray-primary);
        font-family: TTNorms-Medium, Arial, sans-serif;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}
