@import (reference) "colors.less";
@import (reference) "layers.less";

#whats-new-modal {
  .modal-content {
    padding: 0;
  }

  .header {
    padding: 12px 20px 12px 70px;
    position: relative;
    z-index: 10;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.8), 0 2px 4px 0 rgba(0,0,0,.14);

    i {
      position: absolute;
      font-size: 50px;
      left: 5px;
      top: 15px;
      color: @color-teal;
    }

    h3 {
      font-size: 35px;
      color: @color-teal;
      margin: 0;
      padding: 0;
    }
    h4 {
      font-size: 13px;
      color: @color-dark-gray87;
      margin: 0;
      padding: 0;
    }
  }

  .pages {
    background: @color-light-grayED;
    padding: 20px;

    .page {
      margin-bottom: 30px;

      h5 {
        color: @color-gray-primary;
        font-size: 24px;
        margin: -5px 0 10px;
      }

      .item {
        color: @color-gray-primary;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
        border-radius: 4px;

        .item-body {
          padding: 8px 15px;
        }

        > a {
          text-align: right;
          display: block;
          padding: 8px;
          margin-top: -5px;
          margin-bottom: -5px;
          color: @color-teal;
        }
      }

      &.features {
        .item {
          margin-bottom: 20px;
          font-size: 15px;

          .item-title {
            font-size: 26px;
            padding: 8px 15px;
            border-bottom: 1px solid @color-light-grayDB;
          }
        }
      }
      &.content, &.fixes {
        .item {
          border-bottom: 1px solid @color-light-grayDB;
          font-size: 15px;
          border-radius: 0;

          .main-icon {
            font-size: 14px;
            width: 28px;
            height: 28px;
            display: inline-block;
            line-height: 28px;
            text-align: center;
            color: #fff;
            background: @color-primary;
            border-radius: 30px;
            vertical-align: middle;
            margin-right: 12px;

            &.icon-worksheet {
              background: @color-worksheet;
            }
            &.icon-star {
              background: @color-game;
            }
            &.icon-skill-builder {
              background: @color-exercise;
            }
            &.icon-lesson-plan {
              background: @color-lesson-plan;
            }
          }

          &:first-of-type {
            border-top: 1px solid @color-light-grayDB;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          &:last-of-type {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          > a {
            font-size: 13px;
          }
        }
      }
      &.fixes {
        .item {
          position: relative;

          .main-icon {
            position: absolute;
            top: 10px;
            left: 15px;
          }
          .item-body {
            margin-left: 45px;
            padding: 8px 15px 8px 11px;
          }
        }
      }
    }
  }
}
