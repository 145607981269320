@import "colors.less";
@import "screen-sizes.less";
@import (reference) "typography.less";
.buyflow-redirect-loading-overlay {
  position:  fixed;
  z-index:  60000010;
  top:  0;
  right:  0;
  left:  0;
  bottom:  0;
  padding-top:  150px;
  background:  #000;
  background:  rgba(0, 0, 0, 0.7);
  text-align:  center;
  color:  white;
  font-size:  45px;
}
#signup-form {
  .modal-content {
    form {
      margin-top: 18px;

      .type-grade-separator {
        border-top: 1px solid @color-grayC5;
        border-bottom: none;
        margin-bottom: 15px;
      }

      .input-row {
        .forgot {
          font-size: 13px;
        }
        &.has_error {
          .forgot {
            font-size: 15px;
          }
        }

        label.inline {
          display: inline-block;
          vertical-align: middle;

          @media (max-width: @screen-phone) {
            margin-right: 6px;
          }
        }
        label.checkbox {
          display: inline-block;
          vertical-align: top;
          margin-right: 10px;
          font-size: 15px;
          line-height: 18px;

          @media (max-width: 400px) {
            margin-right: 6px;
            font-size: 13px;
          }

          &:last-child {
            margin-right: 0;
          }

          input, span {
            vertical-align: middle;
            display: inline-block;
          }
        }
      }
      .disclaimer {
        text-align: center;
        margin: 15px 0 5px;
        padding: 0;

        p {
          margin: 5px 0;
          .details;
        }
      }
    }

    .choose-account {
      .accounts {
        max-height: 400px;
        overflow-y: auto;
        display: grid;
        .account-container {
          position: relative;
          max-height: 400px;
          &:hover {
            &:not(.google-accounts):not(.facebook-accounts) {
              cursor: pointer;
              background-color: #eaeef7;
            }
          }
          &.google-accounts, &.facebook-accounts {
            order: 10000;
            &.hidden {
              display: none;
            }
          }
          .account-container-header {
            padding-left: 10px;
            font-weight: bold;
          }
          .register-with-google, .register-with-facebook {
            width: calc(100% - 15px);
            max-width: none;
            position: absolute;
            bottom: 20px;
            left: 5px;
            .abcRioButton {
              max-width: none;
              margin-bottom: 0;
            }
          }
          .account {
            .email-icon {
              display: inline-block;
              .user-icon {
                margin-bottom: -10px;
                margin-right: 7px;
              }
              .social-icon {
                margin: 0 10px -5px 5px;
              }
            }
            .account-info {
              display: inline-block;
              width: calc(100% - 60px);
              .email {
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                @media (max-width: @screen-phone-lg) {
                  font-size: 16px;
                }
              }
              .premium-label {
                margin: 5px 0;
                .icon-diamond-outline {
                  font-size: 12px;
                  margin-right: 3px;
                  color: @color-dark-teal;
                }

                span {
                  display: inline-block;
                  background: @color-dark-teal;
                  color: #fff;
                  padding: 1px 4px;
                  font-size: 9px;
                  line-height: 12px;
                  font-weight: bold;
                  letter-spacing: 0.5px;
                  border-radius: 2px;
                  vertical-align: middle;
                  text-transform: uppercase;
                }
              }
              .basic-label {
                span {
                  display: inline-block;
                  color: darken(@color-dark-gray87, 30%);
                  border: 2px solid @color-grayC5;
                  padding: 1px 4px;
                  font-size: 9px;
                  line-height: 12px;
                  font-weight: bold;
                  letter-spacing: 0.5px;
                  border-radius: 4px;
                  vertical-align: middle;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    .sign-in {
      .loginwith {
        margin-bottom: 20px;
      }
      .switch-accounts {
        text-align: center;
        .switch-accounts-link {
          font-size: 16px;
        }
        &.hidden {
          display: none;
        }
      }
    }

    .create-account {
      .signup-form {
        .loginwith {

          &.smaller-social-btns {
            #register-with-google, .register-with-google {
              max-width: none;

              .abcRioButton {
                max-width: none;
              }
            }
          }
        }
      }
    }

    .or-area {
      text-align: center;
      border-bottom: 1px solid @color-grayC5;
      line-height: 0.1em;
      margin: 20px 0 30px;

      span {
        background-color: #fff;
        padding: 0 20px;
        color: @color-grayC5;
      }
    }

    // login with google/clever/facebook buttons:
    .loginwith {
      .abcRioButton {
        border: 1px solid #4285f4;
        border-radius: 3px;
        width: 100% !important;

        .abcRioButtonIcon {
          display: inline-block;
          float: none;
        }
        .abcRioButtonContents {
          display: inline-block;
          font-size: 21px !important;

          @media (max-width: (@screen-phone - 1)) {
            font-size: 18px !important;
          }
        }

        &.fb-btn-container {
          background-color: rgb(59, 89, 152);
          color: white;

          .fb_iframe_widget span {
            display: inline !important;
            padding: 15px;
            margin-right: 15px;
            width: 15px !important;
            height: 15px !important;

            @media (max-width: (@screen-tablet - 1)) {
              display: inline-block !important;
              padding: 13px 17px 13px 21px;
              margin-right: 0;
            }
          }

          .fb-login-button {
            display: inline-block;
          }
          .fb-login-text {
            font-size: 21px;
            line-height: 45px;
            @media (max-width: (@screen-tablet - 1)) {
               vertical-align: top;
            }

            @media (max-width: (@screen-phone - 1)) {
              font-size: 18px !important;
            }
          }

        }
      }
      &.smaller-social-btns {
        .abcRioButton {
          border: 1px solid #4285f4;
          border-radius: 3px;
          width: 100%;
          height: 40px !important;
          box-sizing: border-box;
          max-width: 200px;
          vertical-align: top;

          @media (max-width: @screen-tablet) {
            max-width: 245px;
          }

          .abcRioButtonIcon {
            display: inline-block;
            float: none;
            padding: 8px 8px 10px 30px;
          }
          .abcRioButtonContents {
            display: inline-block;
            font-family: TTNorms-Medium, Arial, sans-serif;
            font-size: 15px !important;
            line-height: 40px;

            @media (max-width: (@screen-phone - 1)) {
              font-size: 15px !important;
            }
          }

          &.margin-right {
            margin-right: 10px;
          }

          &.fb-btn-container {
            .fb_iframe_widget span {
              display: inline-block !important;
              padding: 11px 6px;
              margin-right: 0;

              @media (max-width: (@screen-tablet - 1)) {
                display: inline-block !important;
                padding: 10px 17px 13px 21px;
                margin-right: 0;
              }
            }

            .fb-login-text {
              font-size: 15px;
              line-height: 40px;
              vertical-align: top;

              @media (max-width: (@screen-phone - 1)) {
                font-size: 14px !important;
              }
            }

          }
        }
        #register-with-google, .register-with-google {
          margin-bottom: 0;
          display: inline-block;
          max-width: 205px;
          width: 100%;

          @media (max-width: @screen-tablet) {
            max-width: 245px;
          }

          @media (max-width: 600px) {
            max-width: none;
          }

          div.abcRioButtonIcon {
            background-color: white;
            padding: 5px 5px 4px 5px !important;
            vertical-align: -16px;
          }

          .abcRioButton {
            background-color: #4285f4;
            color: white;
          }
        }
      }
      &.social-login-design {
        .abcRioButton {
          border: 1px solid #4285f4;
          border-radius: 3px;
          width: 100%;
          margin-bottom: 15px;
          height: 40px !important;
          box-sizing: border-box;
          max-width: 205px;
          vertical-align: top;
          text-align: left;

          .abcRioButtonIcon {
            display: inline-block;
            float: none;
            padding: 4px !important;
            margin: 5px 8px;
          }
          .abcRioButtonContents {
            display: inline-block;
            font-size: 15px !important;
            line-height: 40px;

            @media (max-width: (@screen-phone - 1)) {
              font-size: 15px !important;
            }
          }

          &.margin-right {
            margin-right: 0px;
          }

          &.fb-btn-container {
            background-color: rgb(59, 89, 152);
            color: @color-white;

            .font-primary-bold;
            font-size: 16px;
            max-width: none;

            .fb_iframe_widget span {
              display: block;
              padding: 0px;
              margin-right: 0px;
              text-align: left !important;

              @media (max-width: (@screen-tablet - 1)) {
                display: block;
                /*padding: 10px 17px 13px 21px;*/
                margin-right: 0;
              }
            }
          }
        }
        #register-with-google, .register-with-google {
          margin-bottom: 0;
          display: inline-block;
        }
        .or-area {
          text-align: center;
          line-height: 0.1em;
          margin: 15px 0 30px;
          .font-primary-bold;
          border-bottom: none;
          color: @color-text-primary;
        }
      }


      #status {
        color: #f05a3f;
        text-align: center;
      }

      #register-with-google, .register-with-google {
        margin-bottom: 0px;
      }
    }


    @media (max-width: (@screen-tablet - 1)) {
      .create-account {
        form {
          margin-top: 14px;

          .input-row {
            padding-bottom: 5px;
          }

          .type-grade-separator {
            margin: 0 0 5px;
          }
        }

        .loginwith {
          .abcRioButton {

            &.fb-btn-container {

              .fb-login-button-container {
                margin: 0 auto;
                text-align: left;
                width: 180px;

                .fb-login-button {
                  display: inline-block;
                }
              }
            }
          }

          .or-area {
            margin-bottom: 0;

            &.or-area-bottom {
              margin: 10px 0 20px;
            }
          }
        }
      }
    }

    @media (max-width: (600px)) {
      .loginwith {
        &.smaller-social-btns {
          .abcRioButton {
            width: 100% !important;
            max-width: none !important;
            margin-bottom: 15px;
            .abcRioButtonIcon {
              padding: 12px 8px 10px 50px !important;
            }
          }
          #register-with-google, .register-with-google {
            margin-bottom: 0;
            display: block;
          }
        }
        &.social-login-design {
          text-align: center;
          .abcRioButton {

            &.margin-right {
              margin-right: 0px;
            }

            &.fb-btn-container {
              .fb_iframe_widget span {
                display: block;
                padding: 0px;
                margin-right: 0px;
                text-align: left !important;
              }
            }
          }
          #register-with-google, .register-with-google {
            margin-bottom: 0px;
            display: inline-block;
            max-width: 236px;
          }
        }
      }
    }

    @media (max-width: (480px)) {
      padding: 20px;

      .create-account {
        h3 {
          //font-size: 24px;
        }

        .description {
          //font-size: 12px;
          margin: 0 0 5px;
        }

        form {
          margin-top: 10px;

          label {
            //font-size: 12px;

            &.checkbox {
              //font-size: 12px;
              margin: 0;
            }
          }

          .input-row {
            &.iama, &.grades {
              padding-bottom: 0;
            }
          }
        }

        .loginwith {
          .abcRioButton {
            &.fb-btn-container {
              margin-bottom: 10px;
            }
          }

          #register-with-google, .register-with-google {
            margin-bottom: 0;
          }
        }

        .submit {
          width: 100% !important;
        }
      }
    }

  }

  .signup-form {
    &.with-age-gate {
      .description {
        margin: 10px 0;
      }
    }
    &.hidden {
      display: none;
    }
  }

  .signup-age-gate {

    h3 {
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .description {
      margin: 10px auto 20px;
      font-size: 16px;
    }
    .btn-area {
      color: @color-gray-primary;
      position: relative;

      p {
        margin-bottom: 20px;
      }
      .text-bigger {
        font-size: 25px;
        color: @color-text-primary;
        padding: 10px;
      }
      button {
        margin: 15px 10px;
      }
      button.age-gate-pass {
        padding-left: 29px;
        padding-right: 29px;
      }
      button.age-gate-fail {
        padding-left: 44px;
        padding-right: 44px;
      }
      button:focus {
        outline:0;
      }
    }
    .roly-kid {
      display: flex;
      margin: 0 auto -60px;
    }

    @media (max-width: 522px) {
      margin-bottom: 20px;
      .btn-area {
        button.age-gate-pass {
          display: block;
          width: 95%;
          margin-right: 20px;
          order: 1;
        }
        button.age-gate-fail {
          display: block;
          width: 95%;
          margin-right: 20px;
          order: 2;
        }
      }
      .roly-kid {
        display: none;
      }
    }
  }

  .create-account {
    .date-of-birth-age-gate-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: @screen-tablet) {
        margin-bottom: 0;
      }

      .age-gate-title {
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: -5px;
      }

      .user-type-container {
        display: flex;
        flex-direction: column;

        @media (max-width: @screen-tablet) {
          flex-wrap: wrap;
          margin-bottom: 0;
        }

        .user-type-name {
          font-size: 20px;
          font-weight: 600;
        }

        .user-type {
          border: 1px solid @color-grayC5;
          border-radius: 6px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 20px;
          padding: 19px;
          width: 100%;
          transition: all 250ms;

          @media (max-width: @screen-tablet) {
            padding: 20px;
            width: 100%;
          }

          &:last-of-type {
            margin-right: 0;

            @media (max-width: @screen-tablet) {
              margin-bottom: 0;
            }
          }

          &:hover {
            background-color: @color-teal;

            div, p {
              color: white;
            }
          }

          div {
            text-align: center;
            user-select: none;
            width: 100%;
          }
        }
      }
    }

    .screen-age-gate-enter-age {
      display: flex;
      flex-wrap: wrap;

      h4 {
        font-size: 30px;
        margin-bottom: 20px;
        width: 100%;

        @media (max-width: @screen-tablet) {
          font-size: 20px;
        }
      }

      .enter-date-birth {
        width: 100%;
        .date-fields {
          align-content: space-between;
          display: flex;
          width: 100%;

          .input-row {
            margin-right: 15px;
            width: 33%;

            &:last-of-type {
              margin-right: 0;
            }

            .month-of-birth {
              min-width: 90px;
            }
          }
        }

        .date-birth-button {
          margin: 10px auto 10px;
          max-width: 300px;
        }

        .date-birth-error-message {
          color: @color-red;
          margin: 10px auto 20px;
          text-align: center;
        }
      }

      .roly-kid {
        margin: 0 auto -60px;
      }
    }

    &.hidden, .hidden {
      display: none !important;
    }

    .screen-age-gate-enter-age-fail {
      .age-gate-fail-instructions {
        text-align: center;

        div {
          font-size: 30px;
          margin-top: 20px;
        }
      }
    }
  }


  .screen-age-gate-fail {
    display: none;
    margin-bottom: 75px;
    position: relative;
    text-align: left;

    .text-bigger {
      color: @color-text-primary;
      font-size:30px;
    }
    .link-outer {
      margin-top:50px;
      text-align: left;
      font-size:25px;
    }
    .tutu-pre-teen {
      width: 146px;
      height: 185px;
    }
    @media (max-width: 530px) {
      .link-outer {;
        font-size:20px;
      }
      .tutu-pre-teen {
        width: 33%;
        height: auto;
      }
    }
  }

  .floyd-hand {
    position: absolute;
    top: -1%;
    right: 2%;
    z-index: 60000009;
    @media(max-width: 820px) {
      display:none;
    }

  }

  .floyd-body {
    position: absolute;
    top: -8%;
    right: -29%;
    z-index: 60000001;
    @media(max-width: 820px) {
      display: none;
    }
  }
}

#additional-info-request {
  form .btn-container {
    text-align: right;
    margin-top: 15px;
  }
  form .btn {
    margin-left: 20px;
  }
  form label.checkbox {
    margin-right: 10px;
  }
  .twitter-typeahead {
    width: 100%;
    display: block;
  }
  .tt-menu {
    background-color: white;
    border: 1px solid @color-gray-primary;
    box-sizing: border-box;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    color: @color-gray-primary;
    height: 80px;
    margin: 2px 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 8px;
    width: 100%;

    .tt-suggestion:hover {
      cursor: pointer;
      color: #fff;
      background-color: @color-blue-sapphire;
    }
  }
}
