@import "colors.less";
@import (reference) "typography.less";
@import (reference) "utilities.less";

.notifications-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;

  .notifications-icon {
    color: @color-text-primary;
    cursor: pointer;
    font-size: 22px;
    position: relative;
  }

  .num-notifications {
    background-color: @color-primary;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: none;
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    margin-left: -13px;
    position: absolute;
    user-select: none;
    top: 34px;
    text-align: center;
  }
}

.notifications {
  .layer-dropdown;
  padding: 0;
  background-color: white;
  display: none;
  margin-top: 12px;
  position: absolute;
  right: 0;
  user-select: none;
  -webkit-user-select: none;
  width: 446px;
  z-index: 5000040;
  color: @color-gray-primary;

  @media (max-width: (@screen-tablet - 1)) {
    margin: 0;
    text-align: left;
    width: 350px;
  }
  .notification-header {
    .text-large;
    background: #FAFAFA;
    box-shadow: 0 1px 0 0 @color-light-grayED;
    padding: 16px 20px;
    position: relative;
    z-index: inherit;
    text-align: left;
  }

  .notification {
    .text-small;
    .font-primary;
    border-bottom: 1px dotted @color-light-grayDB;
    line-height: 16px;
    padding: 12px 12px 12px 12px;
    position: relative;
    text-align: left;

    @media (max-width: (@screen-tablet - 1)) {
      line-height: 16px;
      padding: 12px 12px 10px 20px;
    }

    a {
      display: block;
      width: 90%;
      white-space: normal;

      @media (max-width: (@screen-tablet - 1)) {
        width: 85%;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &.notification-read {
      a {
        color: @color-text-primary;
      }
    }

    &.notification-unread {
      background-color: white;
    }

    .notification-delete {
      cursor: pointer;
      position: absolute;
      right: 50px;
      top: 30%;
      font-size: 10px;
      &:before {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        color: @color-text-primary;
        background-color: @color-light-grayED;
        line-height: 29px;
      }

      &:hover {
        &:before {
          background-color: @color-destructive;
          color: @color-white;
        }

      }
    }
  }
}

.navbar .notifications-container {
  float: right;
  margin: 0 10px 0 0;
  text-align: center;
  width: 2em;

  .notifications-icon {
    margin: 0;
  }
}

.banner-notifications-container {
  @media print {
    display: none;
  }
}

.banner-notification {
  box-sizing: border-box;
  position: relative;

//  .notification-delete {
//    .notifications .notification .notification-delete;
//    top: 8px;

//  }
}

.messagestack {
  position: relative;
  margin: 0;

  > div {
    margin: 0 auto;

    @media print {
      display: none !important;
    }

    ul {
      border: 1px solid transparent;
      border-radius: 0px;
      list-style: none;
      margin: 0;
      padding: 13px;
      position: relative;

      &.success {
        background-color: @color-alert-success-bg;
        border-color: @color-alert-success-bg;
        color: @color-alert-success-text;
      }

      &.error {
        background-color: @color-alert-error-bg;
        border-color: @color-alert-error-bg;
        color: @color-white;
      }

      &.notice {
        background-color: @color-alert-notice-bg;
        border-color: @color-alert-notice-bg;
        color: @color-white;
      }

      a {
        color: @color-white;
        text-decoration: underline;
        &:hover {
          color: @color-white;
        }
      }

      li {
        .page-width;
        .align-center;
        .font-primary-bold;
        .message {
          display: inline;
          font-size: 18px;
          line-height: 14px;
          vertical-align: text-top;

        }
        i {
          font-size:24px;
        }

        .close-message {
          cursor: pointer;
          /*float: right;
          padding-top: 7px;*/
          position: absolute;
          top: 35%;
          right: 3%;
          .icon-cancel-light {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.flash-sale-banner {
  .text-h5;
  .bg-dark-teal;
  .align-center;
  color: @color-white;
  padding-top: 11px;
  padding-bottom: 11px;
  margin: 0;

  &.free-trial {
    padding: 0;
    .flash-sale-msg {
      &.desktop {
        display: flex;
      }
      &.mobile {
        padding: 10px;
        display: none;
      }
    }

    @media(max-width: @screen-tablet-lg) {
      padding-top: 11px;
      padding-bottom: 11px;
      img {
        display: none;
      }
    }
    @media(max-width: 850px) {
      .flash-sale-msg {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: flex;
        }
      }
    }
  }
}
.flash-sale-banner .flash-sale-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 12px;

}
.flash-sale-banner .flash-sale-msg .btn {
  flex-shrink: 0;
  margin-left: 1em;
}

.bfcm-banner {
  background-color: @color-gray4;
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: cover;

  .desktop {
    display: flex;
    align-items: center;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: @screen-desktop) {
    .desktop{
      display:none;
    }

    .mobile{
      display:flex;
    }
  }
}

.bfcm-banner.black-friday {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Black-Friday-v1a.png");

  @media screen and (max-width: @screen-desktop) {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Black-Friday-bg-mobile.png");
  }
}

.bfcm-banner.cyber-monday {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Monday-Sale-bg.png");

  @media screen and (max-width: @screen-desktop) {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Monday-Sale-bg-mobile.png");
  }
}

.bfcm-banner.cyber-week {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Week-bg.png");

  @media screen and (max-width: @screen-desktop) {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Week-bg-mobile.png");
  }
}

.black-friday-banner-mobile {
  background-color: @color-gray-primary;
  @media(min-width: @screen-desktop+1) {
    display: none;
  }
}

.cyber-monday-banner {
  background-color: @color-blue-sapphire;
  height: 62px;
  padding-top: 0;
  padding-bottom: 0;
  @media(max-width: @screen-desktop) {
    display: none;
  }
}

.cyber-monday-banner-mobile {
  background-color: @color-blue-sapphire;
  @media(min-width: @screen-desktop+1) {
    display: none;
  }
}

.cyber-week-banner {
  background-color: @color-blue-sapphire;
  height: 62px;
  padding-top: 0;
  padding-bottom: 0;
  @media(max-width: @screen-desktop) {
    display: none;
  }
}

.cyber-week-banner-mobile {
  background-color: @color-blue-sapphire;
  @media(min-width: @screen-desktop+1) {
    display: none;
  }
}

.countdown-clock {
  padding-left: 10px;
  height: 40px;

  .box{
    font-family:'Bebas Neue' !important;
    margin:0 8px;
    padding: 3px;
    position: relative;
    /*float: left;*/
    width: 12px;
    height: 14px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    color: @color-white;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f25e82+2,f25e82+49,e55474+49,000000+51,f25e82+53,f25e82+100 */
    background: #f25e82; /* Old browsers */
    background: -moz-linear-gradient(top,  #f25e82 2%, #f25e82 49%, #e55474 49%, #000000 51%, #f25e82 53%, #f25e82 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #f25e82 2%,#f25e82 49%,#e55474 49%,#000000 51%,#f25e82 53%,#f25e82 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #f25e82 2%,#f25e82 49%,#e55474 49%,#000000 51%,#f25e82 53%,#f25e82 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25e82', endColorstr='#f25e82',GradientType=0 ); /* IE6-9 */
}
  small{
    display: block;
    clear: both;
    font-size: 8px;
    line-height: 12px;
    font-weight: 600;
    color: @color-white;
    text-transform: uppercase;
    text-align: center;
    padding-top: 5px;
    font-stretch: ultra-condensed;
  }
  #days, #hours, #minutes, #seconds {
    float:left;
    margin-top: 5px;
  }
}


.net-promoter-score {
  p {
    .text-h4;
    font-weight: 400;
  }
  bottom: -180px;
  opacity: 0;
  z-index: 999999;
  position: fixed;
  left: 0;
  right: 0;
  padding: 8px 5px 15px 5px;
  background: #ffffff;
  border-top: 1px solid #fff;
  text-align: center;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  transition: bottom 0.4s, opacity 0.4s;
  &.open {
    bottom: 0;
    opacity: 1;
  }
  .detractor, .passive, .promoter {
    width: 65%;
    margin: 0 auto;
    display: none;
    &.visible {
      display: block;
    }
    &.subtext {
      font-size: 14px;
      color: @color-gray9;
      font-weight: 200;
    }
    @media(max-width: @screen-desktop) {
      width: 100%;
    }
  }
  textarea {
    border-radius: 6px;
    width: 65%;
    border: 1px solid #c5c5c5;
    font-family: TTNorms-Medium, Arial, sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 20px;
    font-size: 16px;
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    outline-style: none;
    &:hover {
      border: 1px solid #5672c4;
    }
    @media(max-width: @screen-desktop) {
      width: 100%;
    }
  }
}
.net-promoter-score .dismiss {
  position: absolute;
  top: 2px;
  right: 2px;
  color: #999;
  cursor: pointer;
  font-size: 20px;
}
.net-promoter-score .values {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}
.net-promoter-score .values .value {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #dbdbdb;
  border-radius: 30px;
  cursor: pointer;
}
@media (max-width: 479px) {
  .net-promoter-score .values .value {
    width: 24px;
    height: 24px;
  }
}
.net-promoter-score .values .value:hover {
  background: @color-blue-sapphire;
}
.net-promoter-score .values .label {
  font-size: 13px;
  position: absolute;
  bottom: -15px;
}
.net-promoter-score .values .label.left {
  left: 0;
}
.net-promoter-score .values .label.right {
  right: 0;
}
@media print {
  .net-promoter-score {
    display: none !important;
  }
}
