@import "colors.less";
@import "screen-sizes.less";
@import (reference) "layers.less";

#change-password-form {

  .forgot-below {
    display: block;
    margin-top: 4px;
  }

  .input-row {

    &.old_password {
      margin-bottom: 4px;
    }

    &.password {
      height: 80px;
      padding-bottom: 0;
    }

    &.general {
      height: 20px;
      margin-top: 30px;
    }
  }

  .buttons {
    a {
      margin-right: 10px;

      @media (max-width: (340px)) {
        margin-right: 0;
      }
    }

    .btn-primary {
      min-width: 182px;
    }
  }
}

#forgot-password-modal,
.forgot-password {
  .subhead {
    margin-bottom: 30px;
  }

  .input-row {
    &.email-address {
      padding-bottom: 9px;
    }

    &.general.has-error {
      margin-top: 20px;
    }
  }

  .email-changed {
    display: block;
    margin: 9px 0 30px;
  }

  .email-changed-text>p {
    font-size: 14px;
  }

  .buttons {
    a {
      margin-right: 10px;
    }

    .btn-primary {
      min-width: 157px;
    }
  }
}

#edit-name-modal {
  .input-row {
    margin-bottom: 14px;
  }

  .custom-dropdown {
    margin-bottom: 30px;
  }

  .buttons {
    a {
      margin-right: 10px;

      @media (max-width: (@screen-phone)) {
        margin-right: 0;
      }
    }

    .btn-primary {
      min-width: 154px;
    }
  }
}

#change-email-form {
  .input-row {

    &.email-address,
    &.password {
      margin-bottom: 14px;
    }

    &.general {
      height: 20px;
      margin-top: 30px;
    }
  }

  .buttons {
    a {
      margin-right: 10px;

      @media (max-width: (@screen-phone)) {
        margin-right: 0;
      }
    }

    .close {
      min-width: 120px;
    }

    .btn-primary {
      min-width: 182px;
    }
  }
}

#school-info {
  .modal-content {
    max-height: ~"calc(100vh - 50px)";
    overflow-y: auto;
    overflow-x: hidden;
  }

  .school-description,
  .input-row {
    margin-bottom: 30px;
    padding: 0;

    .floating {
      height: 49px;

      span {
        z-index: unset;
      }
    }
  }

  .input-school {
    .multi-school-name {
      display: inline-block;
      width: 89%;

      @media(max-width: @screen-phone-lg) {
        width: 84%;
      }
    }

    .remove-input {
      display: inline-block;
      padding: 8px;
      border-radius: 100%;
      cursor: pointer;
      z-index: 60000010;

      &:hover {
        background-color: rgba(86, 114, 196, 0.1);
      }

      .icon-cancel-light {
        pointer-events: none;
        border-radius: 40px;
        border: solid 1px;
        position: static;
        display: inline-block;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        color: #878787;
        margin: 0;
        padding: 6px 4px 6px 5px;
        cursor: pointer;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }


  .twitter-typeahead {
    width: 100%;
    left: 0;
  }

  .tt-menu {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
    color: @color-gray-primary;
    height: 130px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    top: 90% !important;
    border-radius: 4px;

    .tt-dataset-schools {
      font-size: 16px;

      .tt-suggestion {
        padding: 17px 12px;
      }

      .tt-suggestion:hover {
        cursor: pointer;
        background-color: rgba(86,114,196,0.1);
      }
    }
  }

  .buttons {
    .close {
      margin-right: 10px;
      width: 120px;
    }

    .school-submit-button {
      width: 154px;
    }
  }
}

.edit-billing-modal {
  .cc-info {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    @media (max-width: (@screen-phone-lg)) {
      flex-wrap: wrap;
    }

    .expiration-date {
      @media (max-width: (600px)) {
        min-height: 110px;
      }

      @media (max-width: (@screen-phone-lg)) {
        min-height: 75px;
        width: 100%;
      }

      .dropdowns {
        display: flex;
        width: 220px;

        @media (max-width: (@screen-phone-lg)) {
          width: 100%;
        }

        .custom-dropdown {
          min-width: auto;
          width: 50%;
          max-width: 105px;

          @media (max-width: (@screen-phone-lg)) {
            max-width: none;
          }

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }
    }

    .cvc {
      max-width: 168px;

      @media (max-width: (@screen-phone-lg)) {
        max-width: none;
        width: 100%;
      }

      label {
        height: 50px;
      }
    }
  }

  .errormsg{
    margin-top: 20px;
    font-weight: bolder;
    color: #e73225;
    font-size: 14px;
    text-align: center;
  }

  .input-row {
    margin-bottom: 14px;

    @media (max-width: (@screen-phone-lg)) {
      margin-bottom: 0;
    }

    &.has-error {
      margin-bottom: 0;
    }

    &.general {
      margin-top: 20px;
      min-height: 20px;
    }

    @media (max-width: (600px)) {
      min-height: 75px;
      padding-bottom: 10px;
    }
  }

  .buttons {
    a {
      margin-right: 10px;

      @media (max-width: (@screen-phone)) {
        margin-right: 0;
      }
    }
  }
}


#cancel-autorenew-modal,
.cancel-autorenew-modal {

  &.winback-v2 {
    .screen {
      &.survey {
        .survey-question {
          margin-bottom: 30px;
        }
        label {
          &.radio {
            padding: 10px 0;
            .radio {
              position: absolute;
            }
            .label-text {
              font-size: 16px;
              margin-left: 27px;
            }
          }
          &.more-details {
            padding-top: 15px;
            textarea {
              height: 200px;
              &::placeholder {
                font-size: 14px;
                font-weight: lighter;
              }
            }
            &.hidden {
              display: none;
            }
          }
        }
        .buttons {
          margin-top: 15px;
          float: right;
        }
      }
      .offerbox {
        padding: 0;
        .special-offer-head {
          margin-bottom: 20px;
          svg.dicon {
            vertical-align: top;
            margin-top: -5px;
            width: 60px;
            height: 60px;
          }
          h4 {
            margin: 0;
            color: @color-premium;
            display: inline-block;
            width: 85%;
          }
        }
        .special-offer-body {
          .special-offer-button {
            .btn {
              padding: 11px 55px;
              &.accept-offer {
                float: right;
              }
            }
          }
        }
        .errormsg{
          margin-top: 20px;
          font-weight: bolder;
          color: #e73225;
          font-size: 14px;
          text-align: center;
        }

        &.refund {
          .special-offer-head {
            svg.dicon {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  ul {
    margin-top: 6px;
    margin-bottom: 30px;
  }

  .disclaimer {
    margin: 20px 0 0;
    color: @color-gray8;
    font-style: italic;
  }

  .modal-title {
    .centered {
      text-align: center;
    }
  }

  .screen {
    display: none;

    .btn {
      pointer-events: none;
    }

    &.open {
      display: block;
      .btn {
        pointer-events: auto;
      }
    }

    .step {
      font-size: .9em;
      font-weight: bold;
      font-style: italic;
      margin-bottom: 10px;
    }

    &.survey {
      li {
        span {
          color: @color-blue-sapphire;
        }

        margin: 8px 0;
        padding: 4px;
        cursor: pointer;

        &:hover {
          background: @color-grayF2;
        }
      }
    }

    &.survey-new {
      @media(min-width: @screen-desktop) {
        h3 {
          font-size: 24px;
        }
      }
    }

    &.offer-financing {
      padding: 20px 0 10px;
      text-align: center;
      h3 {
        text-align: center;
        @media(min-width: @screen-desktop) {
          font-size: 24px;
        }
      }
      .offer-container {
        border: 1px solid #DDD;
        border-radius: 6px;
        width: 300px;
        margin: 0 auto;
        padding: 20px 0;
        h2 {
          margin: 0;
        }
      }
      .btn {
        margin-top: 5px;
        margin-bottom: 15px;
        width: 220px;
      }
      p {
        margin-top: 15px;
      }
    }

    &.annualFinancingOfferAccepted {
      text-align: center;
      .icon-ok-circled {
        color: #00C4C9;
        font-size: 54px;
      }
      h3 {
        text-align: center;
      }
      .annual-financing-plan-details {
        width: 300px;
        margin: 0 auto;
        text-align: left;
        @media(max-width: @screen-phone) {
          width: 100%;
        }
        .icon-ok {
          color: #00C4C9;
        }
        .offer-bullet {
          display: flex;
          font-size: 20px;
          &:not(:first-child) {
            margin-top: 14px;
          }
          p {
            font-size: 20px;
          }
        }
      }
      .btn {
        margin: 25px auto 0;
        width: 170px;
      }
    }

    &.more-details {
      textarea {
        margin-bottom: 10px;
        padding: 8px;
        box-sizing: border-box;
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      max-width: 430px;
      margin: 0 auto;

      .btn {
        margin-bottom: 10px;
      }

      a {
        min-width: 243px;
      }
    }

    .offerbox {
      padding: 15px;

      .special-offer-button {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: @screen-xs) {
    ul {
      margin-bottom: 10px;
    }

    .buttons {
      flex-direction: column !important;
    }
  }
}

//// "Change Plan" pop-up modal responsive design style ////
// Set overall pop-up modal's width and horizontal position.
.modal.large.upgrade-modal.open {
  @media (min-width: 851px) {
    width: 600px;
    margin-left: -295px;
  }

  @media (max-width: 850px) {
    margin-left: -30%;
    width: unset;
  }

  @media (max-width: 750px) {
    margin-left: -35%;
    width: 70%;
  }
}

.modal.large.upgrade-modal.open .modal-content{
  @media (min-width: 751px) {
    max-height: 520px;
  }

  @media (min-width: 851px) {
    max-height: 560px;
    max-width: 600px;
    padding: 30px 30px 20px 30px;
  }
  max-height: unset;
}

.modal.large.upgrade-modal.open {
  .hardcoded-upgrade-notice {
    justify-content: space-around;
    flex-direction: column;
    display: flex;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-long {
        padding: 11px 44px;
      }
    }

    @media (min-width: 851px) {
      max-height: 560px;
      max-width: 600px;
      padding: 30px 60px 20px 60px;
    }

    @media(max-width: 1200px) {
      p{
        font-size: 13px;
      }
    }
  }
}
////======================================================////

#upgrade-modal,
.upgrade-modal {

  .btn {
    pointer-events: none;
  }

  &.open {
    .btn {
      pointer-events: auto;
    }
  }

  .error-prompt {
    font-family: TTNorms-Medium, Arial, sans-serif;
    color: #e73225;
  }

  .steps {
    margin-top: 15px;
    position: relative;
    min-height: 280px;

    h4 {
      margin-bottom: 10px;
      margin-top: 0;
      padding-bottom: 10px;
      font-size: 24px;
    }
  }

  .plan-type {
    display: inline-block;
    position: relative;
    @media (min-width: 751px) {
      vertical-align: top;
    }
    @media (max-width: @screen-tablet) {
      margin-right: 0;
      width: 100%;
    }
      .plan {
      display: inline-block;
      width: 100%;
      margin: 0 1%;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: left;

      h5 {
        font-size: 23px;
        margin: 0 0 4px;
      }


      .options label.selected {
        background: @color-blue-sapphire;
        border: 1px solid @color-blue-sapphire;
        border-radius: 3px;
        color: @color-white;
        .font-primary-bold;
      }

      .features {
        font-size: 14px;
      }

      .options {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid @color-grayD;
        font-size: 13px;
        label {
          display: block;
          padding: 7px 5px;
          margin-bottom: 3px;
          cursor: pointer;
          border-radius: 3px;
          box-sizing: border-box;
          border: 1px solid transparent;
          height: 50px;


          input {
            vertical-align: middle;
            margin-right: 10px;
          }

          strong {
            display: inline-block;
            float: right;
          }

          .notice {
            font-style: italic;
            display: block;
            margin-left: 15px;

            &.price {
              text-align: right;
            }

            &.hidden {
              display: none;
            }
          }

          &.lifetime {
            .notice {
              text-align: left;
              margin-top: 3px;
              margin-left: 25px;
            }
          }
        }
      }

      ul {
        padding-left: 30px;
        margin-top: 3px;
        font-size: 13px;
      }
    }
  }
  .confirm {
    display: inline-block;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 250px;
    padding-left: 15px;
    margin-bottom: 0;
    margin-left: 5px;
    border-left: 1px dotted @color-grayD;

    @media (max-width: @screen-tablet) {
      position: static;
      width: 100%;
      padding-left: 0;
      margin-top: 20px;
      border-left: unset;
      margin-left: unset;
      .changes.form {
        max-width: 320px;
        margin: auto;
      }
    }

    h5 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 8px;
    }
    .delayed-message, .downgrade-message {
      p {
        margin-bottom: 15px;
      }

      .plan-name {
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
    .order-details {
      .line-item {
        max-width: 300px;
        padding: 3px 0;
        margin: 0 auto;

        &.plan-name {
          font-weight: bold;
        }

        span {
          float: right;
        }

        &.upgrade-discount {
          color: @color-red;
          padding-top: 0;
        }
        &.total {
          border-top: 1px solid @color-black;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
        .grandtotal {
          .font-primary-bold;
        }
        .grandtotaltext {
          margin-right: auto;
          .font-primary-bold;
        }
        .tax{
          font-size: 14px;
          font-weight: lighter;
          padding-left: 3px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &.applied-balance {
          display: none;
          color: @color-red;
          padding-top: 0;
          &.show {
            display: block;
          }
        }
      }
      .credit-card {
        font-size: 13px;
        color: @color-gray8;
        margin-top: 6px;
        text-align: center;

        a {
          margin-left: 6px;
          display: inline-block;
        }
      }
    }
    .button-holder {
      text-align: center;
      margin-top: 10px;

      .cancel {
        margin-top: 15px;
        font-size: 14px;
      }

      .errormsg {
        margin-top: 10px;
      }

      .compliance-text {
        font-size: 12px;
        margin: 10px 0 0;
        font-style: italic;
      }
    }

    .loading-overlay {
      display: none;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(237, 237, 237, .85);
      z-index: 500;

      p {
        font-size: 20px;
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
        margin-bottom: 10px;
      }

      .ajax-loader {
        position: absolute;
        top: 50%;
        height: 104px;
        width: 100%;
        background: transparent url(/themes/sky/i/worksheets/loading-balls.gif) no-repeat 50% 36%;
      }
    }
    &.has-overlay {
      .loading-overlay {
        display: block;
      }
    }
  }

  @media (max-width: 850px) {
    .confirm {
      width: 200px;
      @media (max-width: @screen-tablet) {
        width: 100%;
      }
    }
    .plan-type {
      // margin-right: 220px;
      @media (max-width: @screen-tablet) {
        margin-right: 0;
      }
    }
    .modal-content {
      padding: 20px;
    }
  }
  @media (max-width: (@screen-sm - 1)) {
    .plan-type .plan {
      display: block;
      width: 100%;
      max-width: 320px;
      margin: 0 auto;

      &.plus {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: (@screen-xs - 1)) {
    .confirm {
      width: 100%;
      position: static;
      padding-left: 0;
      border-left: 0;
      border-bottom: 1px dotted @color-grayD;
      padding-bottom: 10px;
      margin-bottom: 10px;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      text-align: center;

      h4 {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .line-item {
        text-align: left;
      }

      .changes {
        padding: 5px;
      }
    }
    .plan-type {
      margin-right: 0;
    }
  }
}

#collections {
  pointer-events: none;

  h3 {
    font-size: 26px;
  }

  i.btn-round {
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: @color-dark-gray87;
    margin: 0;
    padding: 0;
    cursor: pointer;
    z-index: 10;
    transition: 250ms color, 250ms background;

    &.icon-down-open {
      &:before {
        vertical-align: 2px;
        font-size: 16px;
        transition: 250ms vertical-align, 250ms color;
      }
      &:hover:before {
        vertical-align: -2px;
        color: @color-blue-sapphire;
      }
    }

    &:before {
      margin-left: 0;
      margin-right: 0;
      width: 0.9em;
    }

    &:hover {
      background-color: rgba(86,114,196,.1);
      color: @color-blue-sapphire;
    }
  }

  .icon-cancel-light {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .error {
    color: @color-error;
    height: auto;
    transition: height 250ms;

    &:first-letter{
      text-transform: capitalize;
    }
    &.inactive {
      height: 0;
      opacity: 0;
    }
  }

  .overlay-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    pointer-events: none;
    cursor: pointer;
  }

  .choose-collection {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 350px;
    height: 450px;
    top: 50vh;
    left: 50vw;
    margin-left: -175px;
    margin-top: -225px;
    box-sizing: border-box;
    padding: 40px 40px 20px 40px;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
    z-index: 100002;
    pointer-events: none;

    @media (max-width: 320px){
      width: ~"calc(100% - 20px)";
      margin-left: 10px;
      left: 0;
    }
    @media (max-height: 420px){
      height: ~"calc(100vh - 20px)";
      margin-top: 10px;
      top: 0;
    }

    .list {
      overflow: auto;
    }

    .title {
      margin: 0 0 25px 0;
    }

    .item.new {
      margin: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #ededed;
    }

    .item {
      height: 50px;
      line-height: 50px;
      margin: 15px 0 0 0;
      .icon {
        vertical-align: top;
        text-align: center;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 3px;
        margin-right: 10px;
        font-size: 24px;
        background-size: cover;
        background-position: 50% 0;
        box-shadow: 0 0 0 1px @color-light-grayED inset;
        &.empty {
          font-size: 12px;
          color: @color-gray9;
          background: @color-cream;
        }
      }
      .label {
        vertical-align: top;
        display: inline-flex;
        width: ~"calc(100% - 70px)";
        height: 50px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        align-items: center;
        overflow: hidden;
        span {
          margin-top: 5px;
          max-height: 48px;
        }
      }
      &:hover {
        cursor: pointer;
        color: @color-blue-sapphire;
        .label {
          text-decoration: underline;
        }
      }
    }
  }
  .new-collection {
    position: fixed;
    width: 350px;
    min-height: 250px;
    top: 50vh;
    left: 50vw;
    margin-left: -175px;
    margin-top: -125px;
    box-sizing: border-box;
    padding: 40px 40px 20px 40px;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
    z-index: 100003;
    pointer-events: none;
    @media (max-width: 320px){
      width: ~"calc(100% - 20px)";
      margin-left: 10px;
      left: 0;
    }
    @media (max-height: 220px){
      height: ~"calc(100vh - 20px)";
      margin-top: 10px;
      top: 0;
    }

    .title {
      margin: 0 0 25px 0;
    }
    .actions {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 15px;
      button {
        width: 125px;
      }
    }
  }
  .collection {
    position: fixed;
    width: 100%;
    height: 200px;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
    background: @color-cream;
    z-index: 100001;
    pointer-events: none;

    .ribbon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding: 10px 20px;
      box-sizing: border-box;
      text-align: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 250ms;
      cursor: pointer;

      .icon-up-open {
        display: inline-block;
        vertical-align: -6px;
        margin: 0 10px 0 0;
        transition: 250ms vertical-align, 250ms color;
      }
      .title {
        font-size: 20px;
        line-height: 36px;
        vertical-align: top;
        margin: 0;
        display: inline-block;
        max-width: ~"calc(100% - 90px)";
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        a {
          text-decoration: none;
          pointer-events: none;
          color: @color-gray-primary;
        }
      }
      .count {
        display: inline-block;
        vertical-align: top;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
        background: @color-primary;
        font-weight: 600;
        margin: 0 0 0 10px;
        transition: transform 250ms;
        &.animate {
          transform: scale(1.15);
        }
      }

      .error {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        background: @color-cream;
        padding: 4px 30px 0 30px;
        border-radius: 5px 5px 0 0;
        box-shadow: 0 -2px 2px rgba(0,0,0,.15);
      }

      &:hover {
        .icon-up-open {
          vertical-align: -2px;
          color: @color-blue-sapphire;
        }
      }

      .cancel {
        width: 40px;
        height: 40px;
        display: inline-block;
        margin: 7px 15px 0 0;
        background-color: transparent;
        border-radius: 100px;
        position: absolute;
        right: 10px;
        top: 0;
        transition: background .25s;

        .icon-cancel-light {
          top: 8px;
          left: 9px;
          color: #5672c4;
        }

        &:hover {
          background-color: rgba(86,114,196,.1);
        }
      }
    }

    .full {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      padding: 10px 20px;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 250ms;

      .icon-down-open {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .left {
        display: inline-block;
        vertical-align: top;
        width: 280px;
        margin: 10px 10px 0 30px;
        .icon-left-open {
          margin: -5px 0 0 -5px;
        }
        .title {
          display: inline-block;
          vertical-align: top;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: ~"calc(100vw - 120px)";
          max-width: 250px;
          margin: 0 0 10px 0;
        }
        .change {
          display: inline-block;
          vertical-align: top;
          line-height: 32px;
          margin: 0 -5px 0 -10px;
        }
      }
      .right {
        display: inline-block;
        vertical-align: top;
        width: ~"calc(100% - 350px)";
        overflow: hidden;
        white-space: nowrap;
        -webkit-mask-image: linear-gradient(to left, transparent 0%, #000000 100px);
        mask-image: linear-gradient(to left, transparent 0%, #000000 100px);
        .content-result.in-collection-tray {
          display: inline-block;
          vertical-align: top;
          width: 160px;
          height: 170px;
          min-width: auto;
          min-height: auto;
          animation-fill-mode: forwards;
          .icon-trash-light {
            position: absolute;
            top: 4px;
            right: 4px;
            font-size: 18px;
            width: 36px;
            height: 36px;
            line-height: 36px;
            background-color: #ffffff;
            &:before {
              width: 0.95em;
            }
            &:hover {
              color: @color-blue-sapphire;
            }
          }
          .content-result-link {
            width: 160px;
            height: 170px;
            .front-section {
              width: 160px;
              height: 170px;
              .image {
                height: 115px;
                img {
                  min-height: 115px;
                  background: #ffffff;
                }
              }
              .content-title {
                height: 55px;
                padding: 0 10px;
                .title {
                  height: 28px;
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
              .content-type {
                bottom: 5px;
                left: 10px;
              }
            }
          }
          &.added {
            animation: collection-content-result-expand 250ms;
            @keyframes collection-content-result-expand {
              from {
                opacity: 0;
                width: 0px;
              }
              to {
                opacity: 1;
                width: 160px;
              }
            }
          }
          &.removed {
            .icon-trash-light {
              display: none;
            }
            animation: collection-content-result-contract 250ms;
            @keyframes collection-content-result-contract {
              from {
                opacity: 1;
                width: 160px;
              }
              to {
                opacity: 0;
                width: 0px;
              }
            }
          }
        }
      }
      @media (max-width: 600px) {
        .right {
          display: none;
        }
        .left {
          width: 100%;
          .title {
            max-width: initial;
          }
        }
      }
    }

    &.minimized {
      height: 55px;
      //background: @color-gray-primary;
      .ribbon {
        opacity: 1;
        pointer-events: auto;
      }
      .full {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.active:not(.hidden) {
    &.page-choose-collection {
      .overlay-back {
        opacity: 1;
        pointer-events: auto;
      }
      .choose-collection {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }
    &.page-new-collection {
      .overlay-back {
        opacity: 1;
        pointer-events: auto;
      }
      .new-collection {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }

    &.page-collection .collection {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: auto;
    }
  }
}

#collections-featurewall {
  .roly-collections {
    position: absolute;
    top: 82px;
    right: 20px;
    width: 246px;
    height: 216px;
    z-index: 60000009;
    pointer-events: none;
  }
  .modal-content {
    padding-right: 250px;
    min-height: 350px;

    .buttons {
      margin-top: 80px;
      text-align: left;
      .collections-featurewall-btn-signup {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 750px) {
    width: 75%;
    margin-left: 12%;
    left: 0;
    .icon-cancel {
      right: 25px;
    }
    .modal-content {
      width: ~"calc(100% - 40px)";
      margin-left: 20px;
      padding-right: 210px;
      left: 0;
    }
    .roly-collections {
      transform: scale(.8);
      right: 10px;
    }
  }

  @media (max-width: 550px) {
    .modal-content {
      padding-right: 20px;
      min-height: 250px;
    }
    .roly-collections {
      display: none;
    }
  }
}

#collections-onboarding-end {
  width: 650px;
  .modal-content {
    padding: 0;
    text-align: center;
    .top {
      padding: 25px 50px 35px;
      .success {
        .text-small;
        .color-primary;
        .font-primary-bold;
        padding-bottom: 25px;
        border-bottom: 1px solid @color-light-grayED;
        max-width: 350px;
        margin: 0 auto 20px;
      }
      .tip-title {
        text-align: center;
        color: #9f9f9f;
        margin-bottom: 10px;
      }
      .tip-copy {
        font-size: 30px;
      }
    }
    .next-step {
      .font-primary-bold;
      background-color: #00b5ba;
      color: @color-white;
      font-size: 20px;
      position: relative;

      text-align: right;
      padding: 25px 20px 50px 20px;
      .roly-wrap {
        .roly {
          position: absolute;
          top: -20px;
          left: 52px;
        }
      }

      .actions-container .btn {
        color: @color-white;
        border: 1px solid @color-white;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 20px;

      }
      .actions-container .btn:hover {
        background-color: transparent;
      }
    }
  }

  @media (max-width: 750px) {
    width: auto;
  }
  @media (max-width: 583px) {
    .modal-content {
      .top {
        .tip-copy {
          font-size: 18px;
        }
      }
      .next-step {
        font-size: 16px;
        .roly-wrap {
          .roly {
            transform: scale(.75);
            top: -20px;
            left: 30px;
          }
        }
      }
    }
  }
  @media (max-width: @screen-phone-lg) {
    .modal-content {
      .top {
        padding: 25px 20px 35px;
      }
      .next-step {
        .roly-wrap {
          padding-left: 85px;
          .roly {
            position: absolute;
            top: -20px;
            left: -10px;
          }
        }
      }
    }
  }
  @media (max-width: @screen-phone) {
    .modal-content {
      .next-step {
        text-align: center;
        .actions-container {
          margin-top: 20px;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

#rules-and-legal-modal {
  .modal-content {

  }
}

#my-accounts-modal {
  .accounts {
    max-height: 400px;
    overflow-y: auto;
    .account-container {
      position: relative;
      max-height: 400px;
      &:hover {
        cursor: pointer;
        background-color: #eaeef7;
      }
      &.social-accounts {
        .account:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      &.active {
        background-color: #eaeef7;
        pointer-events: none;
        .account-info {
          .email {
            width: calc(100% - 100px);
            @media(max-width: @screen-phone-lg) {
              width: 100%;
            }
          }
        }
      }
      .logged-in {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        @media(max-width: @screen-phone-lg) {
          position: static;
          margin-left: 50px;
          margin-top: 10px;
        }
      }
      .account-container-header {
        padding-left: 10px;
        font-size: 18px;
        font-weight: bold;
      }
      .account {
        .email-icon {
          display: inline-block;
          .user-icon {
            margin-bottom: -10px;
            margin-right: 7px;
          }
          .social-icon {
            margin: 0 10px -5px 5px;
          }
        }
        .account-info {
          display: inline-block;
          width: calc(100% - 60px);
          .email {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            @media (max-width: @screen-phone-lg) {
              font-size: 16px;
            }
          }
          .premium-label {
            margin: 5px 0;
            .icon-diamond-outline {
              font-size: 12px;
              margin-right: 3px;
              color: @color-dark-teal;
            }

            span {
              display: inline-block;
              background: @color-dark-teal;
              color: #fff;
              padding: 1px 4px;
              font-size: 9px;
              line-height: 12px;
              font-weight: bold;
              letter-spacing: 0.5px;
              border-radius: 2px;
              vertical-align: middle;
              text-transform: uppercase;
            }
          }
          .basic-label {
            span {
              display: inline-block;
              color: darken(@color-dark-gray87, 30%);
              border: 2px solid @color-grayC5;
              padding: 1px 4px;
              font-size: 9px;
              line-height: 12px;
              font-weight: bold;
              letter-spacing: 0.5px;
              border-radius: 4px;
              vertical-align: middle;
              text-transform: uppercase;
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}
.freetrial_modal {
  .modal-content h3, .modal-content h4 {
    line-height: 28px;
    text-align: center;
  }
  .img-celebration {
    text-align: center;
  }
  .btn-container {
    text-align: right;
    margin-top: 30px;
    .msg-no-commitment {
      margin-top: 5px;
    }
    .close {
      margin-right: 15px;
    }
  }
  @media (max-width: @screen-phone-lg) {
    .description {
      display: none;
    }
    .btn-container {
      text-align: center;
      .btn {
        width: 100%;
      }
      .close {
        display: none;
      }
    }
  }
}

#deactivate-account-modal {
  .cannot-restore-text {
    padding-bottom: 25px;
  }
  .general {
    padding-bottom: 15px;
    color: #e73225;
  }
}

.edcom-google {
  cursor: pointer;

  background: #4285F4;
  display: inline-block;
  height: 40px;
  border-radius: 4px;
  width: 200px;
  float: right;

  // Yes, we've got some odd breakpoints here. The FB button has some odd behavior and rather
  // then rewrite the whole dialog just make the Google button play along nicely.

  @media (max-width: 750px) {
    width: 245px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  .google-button {
    background: white;
    width: 34px;
    height: 27px;
    margin: 2px;
    padding-top: 8px;
    display: inline-block;
  }

  .google-logo {
    background-image: url("https://cdn.education.com/files/static/reg-modal/google-logo.png");
    background-repeat: no-repeat;
    height: 35px;
    width: 35px;
    background-size: 18px;
    margin-left: 8px;
  }

  .google-text {
    color: white;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    vertical-align: top;
    margin-left: 8px;
    font-family: 'Roboto', sans-serif;
  }
}

.edcom-google.choose {
  width: 200px;
  margin-left: 0;
  float: none;
}

.clearfloat {
  clear: both;
}
