@import "colors.less";
@import "screen-sizes.less";

body.with-ad-gutter .page-width {
  position: relative;
  @media (max-width: 1623px) {
    left: -135px;
  }
  @media (max-width: 1373px) {
    left: -66px;
  }
  @media (max-width: 1233px) {
    left: 0;
  }
}
body.with-ad-gutter .WorksheetContentDetail .page-width {
  padding-right:105px;
}

.top-with-ad {
  text-align: center;
  padding-top: 35px;

  .ad-contents  {
    display: inline-block;

    .ad {
      padding: 0;
    }
  }
}

.ad {
  padding: 5px 4px;
  border: 1px solid #ddd;
  position: relative;

  @media (max-width: (320px)) {
    border: 0;
    padding: 0;
  }

  &:after {
    display: none; // Hiding this other sites don't label ads
    content: 'ADVERTISEMENT';
    font-size: 9px;
    color: @color-gray-primary;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px;
    background: rgba(221,221,221,.8);
  }

  @media print {
    display: none !important;
  }
}

.ads-gutter-holder {
  width: 100%;
  max-width: 1624px;
  height: 0;
  margin: 0 auto;
  padding-left: 30px;
  position: relative;
  box-sizing: border-box;
  &.full-width-resources {
    max-width: 2100px;
    @media(max-width: (@screen-desktop-lg + 23)) {
      max-width: none;
    }
  }

  .ads-gutter {
    box-sizing: border-box;
    float: right;
    text-align: left;
    width: 312px;
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 1200px;
    &.full-width-resources {
      margin-top: 613px;
    }

    .gutter-top {
      position: absolute;
      top: 0;
      height: 1100px;
    }
    .gutter-bottom {
      position: absolute;
      top: 1200px;
      height: 1200px;
    }

    .gutter-ad-top {
      position: absolute;
      top: 0;
    }
    .gutter-ad-bottom {
      position: absolute;
      top: 1160px;
    }
  }


  .ad-contents {
    max-width: 312px;

    .ad.box {
      display: inline-block;
      padding:0;
    }
  }

  @media (max-width: 1623px) {
    max-width: 1352px;
  }
  @media (max-width: 1373px) {
    .ad-contents {
      max-width: 172px;
    }
    .ads-gutter {
      width: 172px;
    }
  }
  @media (max-width: 1233px) {
    display: none;
  }
}

.ad-container-banner, .ad-container-horizontal {
  background-color: @color-light-grayED;
  border: 0;
  text-align: center;
  border-bottom: 1px solid @color-light-grayDB;

  .ad {
    display: inline-block;
    border: none;
  }
}

.adtype-90 {
  background-color: @color-light-grayED;
  border: 0;
  border-bottom: 1px solid @color-light-grayED;
  padding: 10px 0;
  text-align: center;
}

.ad-container-300x250, .ad-container-300x250-conditional, .ad-container-300x250-mobile {
  display: inline-block;
}

.adtype-300x250 {
 min-height: 250px;
}

.adtype-300x250-conditional {
  min-height: 250px;

  @media (max-width: (@screen-tablet-lg - 1)) {
    min-height: 0;
  }
}

.adtype-300x250-mobile {
  min-height: 0;

  @media (max-width: (@screen-tablet-lg - 1)) {
    min-height: 250px;
  }
}

.adtype-large300-conditional {
  min-height: 250px;

  @media (max-width: (@screen-tablet-lg - 1)) {
    min-height: 0;
  }
}

.adtype-90 {
  min-height: 90px;

  @media (max-width: 747px) {
    min-height: 50px;
  }
}

.adtype-horizontal {
  min-height: 90px;

  @media (max-width: 747px) {
    min-height: 50px;
  }
}

.middle-ads {
  float: left;
  min-height: 250px;
  margin: 10px 30px 10px 0;
  text-align: center;
  width: 300px;

  .ad-contents {
    &:first-of-type {
      float: left;
    }

    &:nth-of-type(2) {
      float: right;
    }
  }
}

.bottom-ads {
  text-align: center;
  clear: both;

  .ad-contents {
    display: inline-block;
    vertical-align: top;

    &:first-of-type {
      margin-right: 50px;

      @media (max-width: @screen-tablet) {
        margin-right: 0;
      }
    }

    @media (max-width: @screen-tablet) {
      padding: 0 0 8px;
    }
  }
}


.sidebar {
  float: right;
  margin-left: 10px;
  margin-bottom: 20px;
  z-index: 1000;
  width: 310px;

  @media (max-width: (@screen-tablet - 1)) {
    margin-left: 0;
    float: none;
  }
}
